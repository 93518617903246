/* Core fonts and headings etc */
@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&family=Lilita+One&family=M+PLUS+1:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300;500&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
:root {
    --white: #fff;
    --black: #0E0E0E;
    --blackTxt: #282828;
    --greyTxt: #5D5D5D;
    --borderGrey: #acacac;
    --borderGreyLght: #cdcdcd;
    --deepRed: #930816;
    --midRed: #AF0E1E;  
    --lghtRed: #CA1728;
    --lghtRedDivider: #CA3F4D;
    --lghtRedBg: #FFF7F8;
    --bgGrey: #F7F7F7;
}

body.esuubi {
    font-family: 'M PLUS 1p', sans-serif;
    font-size: 16px;
    color: var(--greyTxt);
    font-weight: 300;
}

h1, h3, h4, h5 { font-family: 'Lilita One', cursive; text-transform: uppercase; font-weight: normal; }
h1 {
    color: #fff;
    font-size: 56px;
}
h1 span { color: #FF495A; }
h1.blank-header { text-align: center; margin: 172px 0 48px 0; color: var(--blackTxt); }
h2 { margin-top: 0; font-size: 24px; font-family: "Oooh Baby", cursive; }
h3 { color: var(--black); margin-bottom: 22px; font-size: 52px; }
h3 span { display: block; font-size: 78px; }
h4 { color: #DF0D22; font-size: 24px; margin-bottom: 24px; }
h5 { font-size: 40px; margin-bottom: 18px; }
p { margin-bottom: 22px; }

ul { padding-left: 42px; margin: 18px 0; }

a.standard { color: #fff; clear: left; display: block; font-size: 14px; }
a.standard:hover { text-decoration: none; }

blockquote { 
    margin: 22px 0 18px 28px; 
    font-family: 'Oooh Baby', cursive;
    color: var(--blackTxt);
    font-size: 28px;
    position: relative;
}
blockquote::before { 
    content:'"';
    position: absolute;
    top: -8px;
    left: -25px;
    font-style: italic;
    font-size: 36px;
    color: var(--blackTxt);
}

button, a.btn {
    padding: 14px 32px;
    background: var(--lghtRed);
    color: var(--white);
    border-radius: 8px;
    transition: 0.5s;
    cursor: pointer;
    font-family: 'Lilita One', cursive; 
    text-transform: uppercase; 
    font-weight: normal;
    letter-spacing: 1px;
    font-size: 18px;
    display: inline-block;
    text-decoration: none;
    border: none;
}
button.sml, a.btn.sml {
    padding: 18px 28px;
    font-size: 18px;
}
button:hover, a.btn:hover {
    background: var(--deepRed);
}

.mtop {
    margin-top: 100px;
}
.sml {
    font-size: 14px;
}

/* Header and navigation */
header {
    background: var(--white);
    padding: 24px 40px 14px 40px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    box-shadow: rgba(0,0,0,0.1) 1px 1px 5px;
    z-index: 2;
}
header .logo {
    width: 355px;
}
header .logo a {
    outline: none;
    border: none;
}
header .logo img {
    width: 150px;
}
header ul {
    list-style: none;
    text-align: center;
    padding-left: 0;
    margin: 5px 0 0 0;
}
header ul li {
    display: inline-block;
}
header nav ul {
    margin-top: 14px;
    overflow: hidden
}
header nav ul li a {
    font-family: "Lilita One", cursive;
    font-size: 16px;
    text-decoration: none;
    color: var(--blackTxt);
    text-transform: uppercase;
    padding: 22px 22px;
    transition: 0.5s;
}
header nav ul li a:hover {
    color: var(--midRed);
}
header nav ul li a:active, header nav ul li a.active {
    color: var(--lghtRed);
}

header .response ul {
    margin-top: 14px;
}
header .response ul li a {
    margin: 0;
    padding: 14px 22px;
    font-size: 16px;
    margin-left: 10px;
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;
}
header .response ul li a {
    background: var(--white);
    color: var(--lghtRed);
    border: 2px solid var(--lghtRed);
    padding: 12px 20px;
    border-radius: 12px;
    cursor: pointer;
}
header .response ul li:last-child a {
    background: var(--lghtRed);
    color: var(--white);
}
header .response ul li a:hover {
    color: var(--white);
    background: var(--lghtRed);
}
header .response ul li:last-child a:hover {
    background: var(--white);
    color: var(--lghtRed);
}
header .menu-toggle { display: none; }


/* Header slides - starts as a static image, may be updated in future */
.slideshow {
    margin-top: 100px;
    height: calc(100vh - 100px);
    overflow: visibe;
    box-sizing: border-box;
    position: relative;
    background-color: var(--midRed);
}
.slideshow .slide {
    height: 100%;
    width: 100%;
    padding: 33vh 0;
    text-align: center;
    color: var(--white);
    position: absolute;
    left: -102%;
    opacity: 0;
    transition: 1s;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing:border-box;
}
.slideshow .slide.current {
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
}
.slideshow .slide.prev {
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 0;
}
.slideshow .slide h2 {
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
    font-size: 56px;
    margin-bottom: 8px;
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
}
.slideshow .slide h3 {
    color: var(--white);
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
    font-size: 46px;
    font-weight: 100;
    margin-bottom: 0;
    font-family: "Oooh Baby", cursive;
    text-transform: none;
}
.slideshow .slide p {
    font-size: 18px;
    font-weight: 600;
    color: var(--white);
    margin: 0 auto 28px auto;
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
    max-width: 860px;
}

.info-header-wrap {
    margin-top: 80px;
    height: 70vh;
    max-height: 860px;
    position: relative;
}
.info-header-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
}
.info-header-wrap .info-title {
    text-align: center;
    padding-top: 29vh;
}
.info-header-wrap .info-title h1 {
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
}
.info-header-wrap .info-title h2 {
    font-size: 42px;
    margin-bottom: 8px;
    text-shadow: rgba(0, 0, 0, 0.5) 0 0 25px;
    color: var(--white);
    font-weight: 300;
}

/* Aims block */
.aims {
    background: var(--deepRed);
    color: var(--white);
    overflow: auto;
    text-align: center;
}
.aims .third {
    padding: 44px 80px;
    box-sizing: border-box;
    margin: 0;
    width: 33%;
    height: 252px;
    overflow: hidden;
}
.aims .third:nth-child(2) {
    width:34%;
    background: var(--midRed);
}
.aims .third:last-child {
    background: var(--lghtRed);
}
.aims .third h3 { 
    color: var(--white);
    margin-bottom: 14px; 
    font-size: 22px;
}
.aims .third img {
    width: 42px;
    margin: 0 auto 14px auto;
    display: block;
}

/* Centred about block */
.about {
    text-align: center;
    padding: 130px 0;
}
.about.sml-mrgn {
    padding: 65px 0 44px 0;
}
.about h3 {
    margin-bottom: 26px;
}
.about.sml-mrgn h4 {
    color: var(--midRed);
}
.about.sml-mrgn p {
    font-size: 28px;
    font-weight: 500;
    color: var(--deepRed);
    font-family: "Oooh Baby", cursive;
    max-width: 720px;
    margin: 0 auto;
}
.about a.btn {
    margin-top: 24px;
}
   
/* Banner images */
.banner {
    background: url('./../img/children/school-girls.jpg') center center / cover no-repeat;
    padding: 240px 0 210px 0;
    text-align: center;
}
.banner .banner-inner {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
}
.banner .content {
    position: relative;
    z-index: 1;
}
.banner h4 {
    color: var(--white);
    font-size: 52px;
    font-family: "Oooh Baby", cursive;
    text-transform: none;
}
.banner h4, .banner p {
    text-shadow: rgba(0,0,0,0.5) 0 0 25px;
}
.banner .btn {
    margin-top: 32px;
}

.banner.sml {
    background-image: url('./../img/children/school-letterbox.jpg');
    padding: 96px 0 102px 0;
}
.banner.sml img {
    float: left;
    width: 60px;
    margin-right: 22px;
}
.banner.sml h4 {
    font-size: 32px;
    font-family: 'Lilita One';
    text-transform: uppercase;
    margin-top: 12px;
    text-align: left;
}
.banner.sml .btn {
    margin-top: 4px;
}

.banner.head {
    background-image: url('./../img/children/school-class.jpg');
    padding: 225px 0 280px 0;
}
.banner.head h4, .banner.head h1 {
    margin-bottom: 2px;
    text-shadow: rgba(0,0,0,0.5) 0 0 25px;
    font-size: 48px;
}
.banner.head h1 {
    font-size: 72px;
}

.banner.cta {
    background-image: url('./../img/children/school-girls.jpg');
}
.banner.cta:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.banner.cta h4 {
    margin-bottom: 12px;
}
.banner.cta p {
    color: var(--white);
    margin-bottom: 8px;
}

/* Help section - homepage */
.help {
    padding: 92px 0;
    text-align: center;
    overflow: auto;
}
.help .content { overflow: hidden; }
.help h5 {
    color: var(--black);
    text-align: center;
    margin-bottom: 42px;
}
.help .third {
    background: var(--deepRed);
    position: relative;
    min-height: 622px;
}
.help .third:nth-child(2) {
    background: var(--midRed);
}
.help .third:last-child {
    background: var(--lghtRed);
}
.help .third > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    z-index: 0;
}
.help .third .help-img {
    height: 250px;
    background-size: cover;
    background-position: center center;
}
.help .third > div {
    padding: 38px 46px 42px 46px;
}
.help .third div > img {
    width: 44px;
    margin-bottom: 24px;
}
.help .third:last-child div > img {
    width: 40px;
}
.help .third div h5 {
    font-size: 24px;
    color: var(--white);
    margin-bottom: 12px;
}
.help .third div p {
    color: var(--white);
    margin-bottom: 36px;
}
.help .third div a {
    color: var(--white);
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--white);
    margin-bottom: 0;
    font-family: "Lilita One", cursive;
    font-size: 17px;
    text-transform: uppercase;
    transition: 0.5s;
}
.help .third div a:hover {
    border-bottom: none;
    opacity: 0.8;
}

/* Other help block */
.other-help {
    padding: 60px 0 96px 0;
    overflow: auto;
}
.width-half {
    width: 50%;
    float: left;
    margin: 0;
}
.width-half .half-content {
    max-width: 650px;
}
.width-half:first-child .half-content {
    float: right;
    padding-left: 60px;
}
.other-help h5 {
    font-size: 32px;
    color: var(--black);
    margin-bottom: 42px;
}
.other-help .help-ways {
    margin: 60px 0;
    padding-right: 52px;
    box-sizing: border-box;
}
.other-help .other-way {
    margin-bottom: 70px;
}
.other-help .other-way > img {
    background: var(--midRed);
    padding: 18px;
    border-radius: 18px;
    width: 32px;
    float: left;
}
.other-help .other-way > img.hand {
    width: auto;
    height: 37px;
    padding-left: 21px;
    padding-right: 21px;
}
.other-help .other-way > div {
    margin-left: 90px;
}
.other-help .other-way > div h6 {
    font-size: 17px;
    color: var(--black);
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    margin-bottom: 14px;
}
.other-help .other-way > div p {
    margin-bottom: 16px;
}
.other-help .other-way > div a {
    color: var(--midRed);
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 15px;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: 1px solid var(--midRed);
    transition: 0.3s;
}
.other-help .other-way > div a:hover {
    border-bottom-color: var(--white);
}

.news .light-red-bg {
    background: var(--lghtRedBg);
    padding: 60px;
    box-sizing: border-box;
}
.news .light-red-bg article {
    margin-top: 18px;
    padding-top: 18px;
    position: relative;
}
.news .light-red-bg article > div {
    margin-left: 60px;
}
.news .light-red-bg article + article {
    border-top: 1px solid #FADEE2;
}
.news .light-red-bg h5 {
    margin-bottom: 32px;
}
.news .light-red-bg h6 {
    font-family: 'Lilita One', cursive;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--black); 
    margin-bottom: 6px;
}
.news .light-red-bg p {
    font-size: 14px;
    margin-bottom: 6px;
}
.news .light-red-bg p.timestamp {
    float: right;
    font-size: 11px;
    color: var(--greyTxt);
}
.news .light-red-bg a {
    text-decoration: none;
    color: var(--midRed);
    font-weight: 600;
    font-size: 12px;
    transition: 0.5s;
}
.news .light-red-bg a:hover {
    text-decoration: underline;
}

.news .light-red-bg .article-icon, .news .light-red-bg .fb-item:before, .news .light-red-bg .tweet:before, .news .light-red-bg .gallery:before {
    content: "";
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 10px;
    background: var(--midRed) url("./../img/icons/news.png") center center / 20px auto no-repeat;
    position: absolute;
    z-index: 1;
    top: 18px;
    left: 0;
}
.news .light-red-bg .fb-item:before {
    background-image: url('./../img/icons/facebook-white.png');
    background-size: auto 20px;
}
.news .light-red-bg .tweet:before {
    background-image: url('./../img/icons/twitter-white.png');
}
.news .light-red-bg .gallery:before {
    background-image: url('./../img/icons/camera.png');
}
.news .light-red-bg .img > div {
    margin-left: 120px;
}
.news .light-red-bg .img:before {
    top: 42px;
    left: 29px;
}
.news .light-red-bg .img > div.image {
    margin-left: 0;
    width: 100px;
    height: 90px;
    position: absolute;
    top: 18px;
    left: 0;
    background-size: cover;
    border-radius: 10px;
    z-index: 0;
}


/* Left / right image section */
.left-img, .right-img {
    position: relative;
    margin: 62px 0;
    overflow: visible;
    min-height: 550px;
    vertical-align: middle;
}
.left-img .img-left-hang, .right-img .img-right-hang {
    position: absolute;
    width: 48%;
    box-sizing: border-box;
    height: 92.5%;
    background: #ddd url('./../img/family.jpg') center center / cover no-repeat;
    top: 32px;
    left: 0;
    font-size: 0;
}
.right-img .img-right-hang {
    left: auto;
    right: 0;
}
.left-img .content {
    padding: 26px;
    box-sizing: border-box;
}
.right-img .written-content { padding-top: 100px; }
.left-img .written-content { padding-top: 65px; }
.left-img h3 {
    font-size: 52px;
    margin-top: 0;
    margin-bottom: 5px;
}
.left-img h4, .right-img h4 {
    color: var(--midRed);
    font-size: 26px;
    margin-top: 0;
}

/* Forms */
.input {
    margin-bottom: 22px;
}
.input.half {
    width: 47.5%;
    margin-right: 2.5%;
    float: left;
}
.input:nth-child(odd) {
    clear: left;
}
.input label {
    color: var(--blueTxt);
    opacity: 0.6;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
    line-height: 16px;
}
.input input, .textarea textarea {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: var(--blueTxt);
    padding: 12px 22px;
    display: block;
    width: 97.5%;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid var(--borderGreyLght);
}
.input.half input {
    width: 100%;
}
.input input:focus,
form .textarea textarea:focus {
    outline: none;
    border-color: var(--fadedBlueBorder);
}
form .textarea textarea {
    min-height: 225px;
    margin-bottom: 32px;
}
form.contact .input.checkbox {
    margin-bottom: 32px;
}
form.contact .input input[type="checkbox"] {
    float: left;
    width: 20px;
    margin-right: 12px;
}
.val-msg {
    color: var(--red);
    font-size: 14px;
    margin-top: 4px;
    display: block;
}

/* Footer */
footer.final {
    background: var(--midRed);
    color: #fff;
    padding: 40px 0;
    box-sizing: border-box;
    text-align: center;
}
footer.final img.logo {
    width: 165px;
    margin: 0 auto 12px auto;
}
footer.final nav ul {
    padding-left: 0;
}
footer.final nav ul li {
    display: inline-block;
}
footer.final nav ul li a {
    padding: 0 22px;
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    font-size: 16px;
}
footer.final nav ul li a.btn {
    background: none;
}
footer.final .smallprint {
    margin: 46px 0 0 0;
    border-top: 1px solid var(--lghtRedDivider);
    padding-top: 42px;
    overflow: hidden;
}
footer.final .smallprint p {
    margin-bottom: 12px;
}
footer.final .smallprint p.sml {
    margin-top: 24px;
    margin-bottom: 28px;
    font-size: 0.8em;
}
footer.final a {
    color: #fff;
    text-decoration: none;
    transition: 0.5s;
}
footer.final a:hover {
    opacity: 0.5;
}
footer.final .smallprint ul.social {
    margin: 0;
}
footer.final .smallprint ul.social li {
    display: inline-block;
}
footer.final .smallprint ul.social li a {
    padding: 5px 12px;
}
footer.final .smallprint ul.social li:first-child img {
    height: 26px;
}
footer.final .smallprint ul.social li:last-child img {
    /* width: 30px; */
}

/* Donation pages */
.donate .half {
    width: 40%;
    height: 100vh;
    float: left;
}
.donate .half.form {
    width: 60%;
}
.donate .half.title {
    background: var(--midRed);
    padding: 56px;
    box-sizing: border-box;
    position: relative;
}
.donate .half.title img {
    max-width: 155px;
}
.donate .half.title h1 {
    margin-top: 25vh;
    font-size: 48px;
}
.donate .half.title h4 {
    margin-top: 5px;
    font-size: 32px;
    color: var(--white);
    opacity: 0.5;
    text-transform: none;
    font-family: "Oooh Baby", cursive;
}
.donate .half.title footer {
    position: absolute;
    bottom: 42px;
    left: 42px;
    background: none;
    color: var(--white);
    font-size: 12px;
}
.donate .half.title footer p {
    margin: 5px 0 0 0;
}
.donate .half.form .form {
    margin: 10vh auto;
    width: 80%;
    max-width: 620px;
    max-height: 80vh;
    overflow-y: auto;
}
.donate .half .form h2 {
    color: var(--blackTxt);
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 18px;
}
.donate .half .form ul.tab-btns {
    list-style: none;
    margin: 0 0 42px 0;
    padding-left: 0;
}
.donate .half .form ul.tab-btns li {
    display: inline-block;
    margin-right: 15px;
}
.donate .half .form ul.tab-btns li button {
    border: 1px solid var(--lghtRed);
    border-radius: 0;
    background: var(--white);
    color: var(--greyTxt);
    padding: 14px 22px;
    font-size: 18px;
}
.donate .half .form ul.tab-btns li button.current {
    background: var(--lghtRed);
    color: var(--white);
}

.donate ul.amount-options {
    list-style: none;
    margin: 0;
    padding-left: 0;
}
.donate ul.amount-options li {
    margin: 0 0 32px 0;
    clear:left;
}
.donate .amount-options button {
    border: none;
    background: none;
    color: var(--blackTxt);
    padding: 0;
}
.donate .amount-options button .amount {
    display: block;
    float: left;
    width: 90px;
    height: 90px;
    border: 1px solid var(--lghtRed);
    padding: 26px 12px;
    box-sizing: border-box;
    font-size: 32px;
    margin: 0 26px 0 0;
}
.donate .amount-options button.monthly .amount {
    padding: 20px 12px;
}
.donate .amount-options li button .amount span {
    font-family: 'M PLUS 1p', sans-serif;
    font-size: 10px;
    display: block;
    text-transform: none;
}
.donate .amount-options li.current button .amount {
    background: var(--lghtRed);
    color: var(--white);
}
.donate .amount-options li button .desc {
    color: var(--greyTxt);
    font-family: 'M PLUS 1p', sans-serif;
    letter-spacing: 0;
    text-transform: none;
    font-size: 16px;
    padding: 22px 0;
    max-width: 65%;
    display: block;
    text-align: left;
    float: left;
}
.donate .amount-options li.current button .desc {
    color: var(--blackTxt);
}
.donate .amount-options li button .desc span span {
    color: var(--deepRed);
    font-weight: 600;
}
.donate .input {
    display: block;
    margin: 12px 0 18px 0;
}
.donate .amount-options li .input {
    margin: 12px 0 32px 0;
}
.donate .input label {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}
.donate .input input {
    padding: 11px 22px;
    border-radius: 5px;
    border: 1px solid var(--borderGrey);
    box-sizing: border-box;
    margin-right: 15px;
    font-family: 'M PLUS 1p', sans-serif;
    position: relative;
    color: var(--blackTxt);
    width: 100%;
}
.donate .amount-options li .input input {
    font-weight: 600;
    width: 50%;
    padding: 11px 22px 13px 38px;
    font-size: 18px;
    background: url('./../img/core/pound.png') left 12px center / 14px auto no-repeat;
}
.donate .input input:focus, .donate .amount-options li.current .input input {
    border: 1px solid var(--lghtRed);
    outline: none;
}
.donate .input.checkbox {
    margin: 24px 0 32px 0;
    overflow: auto;
}
.donate .input.checkbox input {
    float: left;
    width: auto;
    margin-top: 5px;
}
.donate .confirm-pane {
    background: var(--lghtRedBg);
    padding: 12px 22px;
    margin-bottom: 32px;
}
.donate .confirm-pane h3 {
    float:left;
    margin-right: 22px;
}
.donate .confirm-pane p {
    margin: 9px 0;
    font-size: 14px;
}
.pay-btn, .donate button#submit {
    border: 2px solid var(--lghtRed);
    background: var(--lghtRed);
    margin-top: 18px;
    padding: 14px 22px;
    font-size: 18px;
    margin-right: 15px;
}
.donate button#submit {
    margin-top: 32px;
}
.pay-btn:hover,
.donate button#submit:hover {
    background: var(--white);
    color: var(--lghtRed);
}

.donate .flash-msg.mtop {
    margin-top: 32px;
}

/* Slider */
.slider {
    background: var(--lghtRedBg);
    padding: 72px 0;
}
.slider h3 {
    text-align: center;
    font-size: 48px;
    margin-bottom: 42px;
}
.slider h5 {
    font-size: 28px;
    margin-bottom: 14px;
    color: var(--lghtRed);
}
.slider .img {
    width: 175px;
    height: 175px;
    background: url('./../img/children/sponsor.jpg') center center / cover no-repeat;
    border-radius: 100px;
    float: left;
    margin-right: 25px;
}
.slider button {
    background: none;
    border: none;
    float: left;
    color: #aaa;
    font-size: 42px;
    margin: 42px 32px 0 0;
    transition: 0.5s;
}
.slider button:hover {
    opacity: 0.5;
}
.slider button.nxt {
    float: right;
}
.slider .wording {
    width: 90%;
    padding-left: 320px;
    box-sizing: border-box;
}
.slider .car-content {
    transition: 0.5s;
}
.slider .car-content.faded-out {
    opacity: 0;
}

/* Latest styles - masonry */
.latest-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -48px; /* gutter size offset */
  margin-bottom: 72px;
  width: auto;
}
.latest-grid-col {
  padding-left: 48px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
article.article .other-articles .latest-item,
.latest-grid-col > .latest-item { /* change div to reference your elements you put in <Masonry> */
  background: var(--midRed);
  margin-bottom: 48px;
  color: var(--white);
  position: relative;
  font-size: 16px;
}
article.article .other-articles .latest-item {
    float: left;
    width: 29%;
    margin: 0 2%;
    position: relative;
}
.latest-grid .latest-item a.full-lnk, article.article .other-articles .latest-item a.full-lnk, .news-item a.full-lnk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
}
article.article .other-articles .latest-item h3,
.latest-grid-col > .latest-item h3 {
    color: var(--white);
    font-size: 24px;
    padding: 2px 0 2px 40px;
    margin-bottom: 5px;
}
article.article .other-articles .latest-item h3,
.latest-grid-col > .latest-item.news h3 {
    background: url('./../img/icons/news-icon.png') left center / 28px auto no-repeat;
}
.latest-grid-col > .latest-item.tweet h3 {
    background: url('./../img/icons/twitter-white-big.png') left center / 28px auto no-repeat;
}
.latest-grid-col > .latest-item.fb h3 {
    background: url('./../img/icons/facebook-white.svg') left center / 28px auto no-repeat;
}
article.article .other-articles .latest-item .copy,
.latest-grid-col > .latest-item .copy {
    padding: 28px;
}
article.article .other-articles .latest-item img,
.latest-grid-col > .latest-item img {
    width: 100%;
}
article.article .other-articles .latest-item p,
.latest-grid-col > .latest-item .copy p {
    margin-bottom: 18px;
    
}

article.article .other-articles .latest-item p.datetime,
.latest-grid-col > .latest-item .copy p.datetime {
    font-size: 14px;
    font-style: italic;
    margin-bottom: 12px;
}
article.article .other-articles .latest-item .copy a.lnk,
.latest-grid-col > .latest-item .copy a.lnk {
    color: var(--white);
    font-family: "Lilita One", cursive;
    text-transform: uppercase;
    text-decoration: none;
    padding-bottom: 1px;
    border-bottom: 1px solid var(--white);
    transition: 0.3s;
}
article.article .other-articles .latest-item a.lnk:hover,
.latest-grid-col > .latest-item .copy a.lnk:hover {
    border-bottom-color: var(--midRed);
}

.latest-grid-col > .latest-item img.other-article-img
{
    width: 100%;
    height:300px;
    object-fit: cover;
}

.latest-grid-col > .latest-item .latest-article-draft{
    position: absolute;
    right:12px;
    top:12px;
    /* font-weight: 500; */
    background-color: var(--black);
    padding:12px;
    text-transform: uppercase;
    font-size: 13px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    border-radius: 5px;
}

/* News article page */
article.article {
    margin-top: 170px;
}
article.article img.header, article.article img.header-img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 32px;
}
article.article .header-img .edit-img {
    height: 100%;
}
article.article h1 {
    color: var(--blackTxt);
    font-size: 45px;
    margin-bottom: 16px;
}
article.article h4 {
    font-size: 32px;
    color: var(--blackTxt);
    margin-bottom: 28px;
}
article.article h6 {
    font-weight: 600;
    font-style: italic;
    color: var(--midRed);
    font-size: 15px;
    margin-bottom: 32px;
}
article.article h6 span {
    margin: 0 12px;
}
article.article .article-body {
    margin-bottom: 72px;
}
.article .other-articles {
    padding-top: 62px;
    border-top: 1px solid #D8D8D8;
}

article.article .other-articles {
    margin-bottom: 112px;
    overflow: auto;
}

article.article .other-articles .other-article-img{
    width: 100%;
    height: 315px;
    object-fit: cover;
}

.whole-admin button.react-datepicker__navigation{
    padding:14px;
    background: unset;
    border:unset;
}

.error-screen {
    margin-top: 120px;
    margin-bottom: 125px;
    text-align:center;
}
.error-screen img {
    width: 180px;
    margin: 0 auto 44px auto;
    display: block;
}
.error-screen h1 {
    color: var(--midRed);
    font-size: 32px;
    margin-bottom: 22px;
}

/* Smaller screen styles */
@media only screen and (max-width: 1370px) {

    header .response ul li a, header .response ul li a.secondary { font-size: 15px; padding: 10px 16px; }
    header .response ul li a.secondary { font-size: 15px; padding: 8px 14px; }
    header .logo { width: 325px; }
    header .width-6 { width: auto; }
    header nav ul li a { font-size: 15px; padding: 22px 18px; }

}

@media only screen and (max-width: 1320px)  {
    header .width-6.centred { margin: 0 0 0 auto; text-align: right; }
    header .width-6.centred ul { text-align: right; }
    header .logo { width: auto; }
    h1 { font-size: 52px; }
}

@media only screen and (max-width: 1140px) {

    .aims .third { height: 300px; }

}

@media only screen and (max-width: 1020px) {

    header .menu-toggle { 
        display: block; 
        background: none;
        border: none;
        color: var(--blackTxt);
        font-size: 16px;
        float: right;
        border-top: 5px solid var(--blackTxt);
        border-bottom: 5px solid var(--blackTxt);
        padding: 6px 0;
        border-radius: 0;
        margin: 8px 0;
    }
    header .menu-toggle:hover { color: var(--greyTxt); border-top-color: var(--greyTxt); border-bottom-color: var(--greyTxt); }
    header .response { min-width: 420px; }
    header .response ul { padding-top: 5px; }
    header .width-6.centred { display: none; position: fixed; top: 101px; right: 0; background: #fff; }
    header .width-6.centred.open { display: block; }
    header nav ul li { display: block; }
    header nav ul li a { display: block; font-size: 16px; padding: 14px 56px; }

    .left-img .img-left-hang, .right-img .img-right-hang { width: 35%; }
    .left-img .written-content { width: 60%; }
    .right-img .written-content { width: 60%; }

    .banner.cta .width-7 { width: 75%; }

    .donate .half .form ul.tab-btns li button { font-size: 16px; padding: 12px 16px; }
    .donate .half.title h1 { font-size: 38px; }
    .donate .half.title h4 { font-size: 26px; }
}
@media only screen and (max-width: 890px) {
    article.article .other-articles .latest-item {
        width: 98%;
        margin: 0 1% 32px 1%;
    
    }
}
@media only screen and (max-width: 890px) {
    .aims .third { padding: 44px; }
    .help .options .third div { padding: 38px 20px 42px 20px; }

    .two-col .width-half { width: 100%; float: none; overflow: auto; padding-right: 60px; padding-left: 60px; }
    .two-col.news .width-half.light-red-bg { background: none; padding-left: 60px; margin-left: auto; margin-right: auto; float: none; }
    .two-col.news .width-half.light-red-bg .half-content { margin-left: auto; margin-right: auto; float: none; }
    .two-col .width-half.help-ways { background: var(--lghtRedBg); padding-top: 72px; margin-top: 0; }
    .two-col .width-half:first-child .half-content { padding-left: 0; float: none; margin-left: auto; margin-right: auto; }

    .slideshow .slide { padding: 20vh 0; }
    .slideshow .slide h2 { font-size: 42px; }
    .slideshow .slide h3 { font-size: 32px; }
    .slideshow .slide p { font-size: 16px; }

    .donate .half { width: 100%; height: auto; }
    .donate .half.title { padding: 32px; }
    .donate .half.title img { max-width: 120px; }
    .donate .half.title h1 { margin-top: 12px; float: right; font-size: 26px; }
    .donate .half.title h4 { display: none; }
    .donate .half.form { width: 100%; }
    .donate .half.form .form { width: 90%; max-width: 720px; max-height: 999999px; }
    .pay-btn { font-size: 15px; padding: 12px 18px; }

    footer.final nav ul li { margin-bottom: 18px; }
}

@media only screen and (max-width: 760px) {
    .help .options .third { width: 100%; float: none; height: 165px; overflow: hidden; margin: 12px 0; }
    .help .options .third > img { height: 165px; float: left; width: auto; margin-right: 22px; }
    .help .options .third div { padding: 38px 42px 42px 42px; }
    .help .options .third div > img { margin: 0 auto 22px auto; display: block;}
    .help .options .third div>a { text-align: center; }

    .banner { padding: 150px 0 120px 0; }
    .banner h4 { font-size: 36px; }

    .info-header-wrap { height: 45vh; }
    .info-header-wrap .info-title { padding-top: 15vh; }
    .info-header-wrap .info-title h2 { font-size: 32px; }

    .about.sml-mrgn { padding-bottom: 80px; }
    footer.final .smallprint { overflow: hidden; }
}

@media only screen and (max-width: 720px) {

    header .response { min-width: 295px; }

    .header-slides { height: 650px; padding: 200px 0; }
    .header-slides .width-8 { width: 80%; }
    .header-slides h2 { font-size: 32px; }
    .header-slides h1 { font-size: 40px; }

    .aims .third { width: 100% !important; display:block; float: none; height: auto; padding: 32px 10%; }

    header .response ul li a.secondary { display: inline-block; }

}
@media only screen and (max-width: 680px) {
    .left-img .img-left-hang, .right-img .img-right-hang { position: relative; height: 380px; width: 100%; }
    .left-img .written-content,
    .right-img .written-content { width: 98%; margin: 0 1%; }
}
@media only screen and (max-width: 620px) {
    .help .options .third div { padding: 22px; }
    .help .options .third div h5 { font-size: 20px; margin-bottom: 8px; }
    .help .options .third div p { font-size: 15px; }
}

@media only screen and (max-width: 620px) {

    header .logo { width: 106px; }
    header .logo img { width: 106px; }

    header .response ul { padding-top: 2px; }
    .slideshow { margin-top: 85px; }

    header .width-6.centred {
        top: 94px;
        min-width: 100%;
    }
    header nav ul li a {
        text-align: center;
        padding: 18px 0;
    }
    header .response {
        min-width: 240px;
    }
    header .response ul li a { font-size: 12px; padding: 6px 12px; }

    .slideshow { margin-top: 85px; }
    .donate .amount-options li button .desc { padding: 9px 0; }

}

@media only screen and (max-width: 500px) {
    header .response {
        min-width: 180px;
    }
    header .response ul li:first-child a { display: none; }
    .donate .half .form ul.tab-btns li { margin-right: 5px; }
    .donate .half .form ul.tab-btns li button { font-size: 13px; padding: 8px 10px; }
    .donate .half.form .form { width: 95%; max-width: 720px; max-height: 999999px; margin-top: 42px; }

}